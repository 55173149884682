<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        Filters
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
        >
          <label>Creator</label>
          <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :value="adminFilter"
              :options="adminOptions"
              class="w-100"
              :reduce="val => val.value"
              @input="(val) => $emit('update:adminFilter', val)"
          />
        </b-col>
        <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
        >
          <label>Speciality</label>
          <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :value="specialityFilter"
              :options="specialityOptions"
              class="w-100"
              :reduce="val => val.value"
              @input="(val) => $emit('update:specialityFilter', val)"
          />
        </b-col>
        <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
        >
          <label>Status</label>
          <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :value="statusFilter"
              :options="statusOptions"
              class="w-100"
              :reduce="val => val.value"
              @input="(val) => $emit('update:statusFilter', val)"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
  },
  props: {
    roleFilter: {
      type: [String, null],
      default: null,
    },
    verifiedFilter: {
      type: [String, null],
      default: null,
    },
    planFilter: {
      type: [String, null],
      default: null,
    },
    statusFilter: {
      type: [String, null],
      default: null,
    },
    specialityFilter: {
      type: [String, null],
      default: null,
    },
    specialityOptions: {
      type: Array,
      required: true,
    },
    adminFilter: {
      type: [String, null],
      default: null,
    },
    adminOptions: {
      type: Array,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    verifiedOptions: {
      type: Array,
      required: true,
    },
    planOptions: {
      type: Array,
      required: true,
    },
    statusOptions: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
