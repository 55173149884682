import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from "@/router";

export default function useCourseReviewsList() {
  // Use toast
  const toast = useToast()

  const refCourseReviewsListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'user', sortable: false },
    { key: 'rate', sortable: true },
    { key: 'comment', sortable: false },
    { key: 'date', sortable: true },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalCourseReviews = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const specialityFilter = ref(null)
  const planFilter = ref(null)
  const verifiedFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refCourseReviewsListTable.value ? refCourseReviewsListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalCourseReviews.value,
    }
  })

  const refetchData = () => {
    refCourseReviewsListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const fetchReviews = (ctx, callback) => {
    store
      .dispatch('courses/fetchReviews', {
        id: router.currentRoute.params.id,
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        role: roleFilter.value,
        speciality: specialityFilter.value,
        plan: planFilter.value,
        verified: verifiedFilter.value,
        status: statusFilter.value,
      })
      .then(response => {
        const { reviews, total } = response.data

        callback(reviews)
        totalCourseReviews.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserIsActiveVariant = is_active => {
    if (is_active === 0) return 'warning'
    if (is_active === 1) return 'success'
    if (is_active === 'inactive') return 'secondary'
    return 'primary'
  }

  const resolveUserVerifiedVariant = verified => {
    if (verified === 'not verified') return 'warning'
    if (verified === 'verified') return 'success'
    if (verified === 'inactive') return 'secondary'
    return 'primary'
  }

  return {
    fetchReviews,
    tableColumns,
    perPage,
    currentPage,
    totalCourseReviews,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refCourseReviewsListTable,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserIsActiveVariant,
    resolveUserVerifiedVariant,
    refetchData,

    // Extra Filters
    roleFilter,
    specialityFilter,
    planFilter,
    verifiedFilter,
    statusFilter,
  }
}
